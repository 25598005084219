<template>
  <div>
    <ListadoEquipo />
  </div>
</template>

<script>
import BreadcrumbComponent from '../../../layouts/components/BreadcrumbComponent.vue';
import ListadoEquipo from "../components/ListadoEquipoComponent";

export default {
  components: {
    ListadoEquipo,
  },
  async mounted() {
    this.$store.state.project.breadcrumbs = [{ pageName: 'Equipo comercial', route: '/equipo-comercial' }];
  }
};
</script>

<style lang="scss" scoped>
</style>
