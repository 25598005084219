<template>
  <div class="p-4">
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" class="w-full">
      <p class="text-sm">ID: {{ userId }}</p>
    </vs-col>
    <div v-if="user">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-avatar
            v-if="!imageReadyToUplad"
            @click.native="$refs.btnPerfil.click()"
            :src="user.avatar"
            size="126px"
            class="mt-4 mb-4 specialAvatarBorder"
            vs-align="center"
          />
          <vs-avatar
            v-if="imageReadyToUplad"
            size="156px"
            :src="profileNewImageTemp"
            class="mt-4 mb-4 specialAvatarBorder"
            vs-align="center"
          />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <h4>{{ firstName }} {{ lastName }}</h4>
        </vs-col>
      </vs-row>
      <vs-row class="mb-4 mt-2">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-chip :color="statusColor(user.accounts[0].active)">
            <strong>{{ getStatus(user.accounts[0].active) }}</strong>
          </vs-chip>
        </vs-col>
      </vs-row>
      <div class="vx-row">
        <div class="vx-col" id="avatar-col">
          <div class="mb-4">
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  v-show="false"
                  ref="btnPerfil"
                  @change="mostrarImagenPerfil($event)"
                />
              </vs-col>
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="12"
                >
                  <vs-button
                    v-if="
                      (user.avatar != profileNewImageTemp) &
                        (!profileNewImageTemp == '')
                    "
                    class="lg:w-1/1 m-1 mt-4 mb-0"
                    color="success"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="savePic(email)"
                  ></vs-button>
                  <vs-button
                    v-if="
                      (user.avatar != profileNewImageTemp) &
                        (!profileNewImageTemp == '')
                    "
                    class="lg:w-1/1 m-1 mt-4 mb-0"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-x-circle"
                    @click="cancelUpload()"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </vs-row>
          </div>
        </div>

        <vs-row vs-w="12" class="p-1">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="w-full"
          >
            <vs-input
              class="w-full"
              label="Nombre"
              v-model="firstName"
              icon-no-border
            />

            <vs-button
              v-if="(user.first_name != firstName) & (!firstName == '')"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="
                updateUserProfile('first_name', firstName, 'de primer nombre.')
              "
            ></vs-button>
          </vs-col>
        </vs-row>

        <vs-row vs-w="12" class="p-1">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="w-full"
          >
            <vs-input
              label="Apellido"
              v-model="lastName"
              class="w-full"
              icon-no-border
            />

            <vs-button
              v-if="(user.last_name != lastName) & (!lastName == '')"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="
                updateUserProfile('last_name', lastName, 'de segundo nombre.')
              "
            ></vs-button>
          </vs-col>
        </vs-row>

        <vs-row vs-w="12" class="p-1">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="w-full"
          >
            <vs-input
              class="w-full mt-2"
              label="E-mail"
              v-model="email"
              icon-no-border
            />

            <vs-button
              v-if="(user.accounts[0].email != email) & (!email == '')"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="updateUserAccount('email', email, 'de correo.', 'email')"
            ></vs-button>
          </vs-col>
        </vs-row>

        <vs-row vs-w="4" class="p-1">
          <vs-col vs-justify="center" vs-align="center" cass="w-full">
            <label for="" class="mt-6 vs-input--label">Código de área</label>
            <v-select
              class="fZise p-2"
              v-model="countryArea"
              :options="countries"
            />
            <vs-button
              v-if="user.area_code != countryAreaStored"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="
                updateUserProfile('area_code', countryArea, 'de teléfono.')
              "
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row vs-w="8" class="p-1">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="w-full"
          >
            <vs-input
              class="w-full mt-2"
              label="Teléfono"
              v-model="telephone"
              icon-no-border
            />
            <vs-button
              v-if="(user.phone != telephone) & (!telephone == '')"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="
                updateUserProfile('phone', telephone, 'de teléfono.', 'phone')
              "
            ></vs-button>
          </vs-col>
        </vs-row>
        <p class="p-2">Rol de usuario:</p>
        <vs-row vs-w="12" class="p-1">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="w-full"
          >
            <v-select
              v-model="role"
              :options="roles"
              label="name"
              class="w-full"
            />
            <vs-button
              v-if="
                (user.accounts[0].account_roles[0].role.name != role) &
                  (!role == '')
              "
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-1 m-2"
              @click="updateUserRol('roll', role, 'de roll.', 'role')"
            ></vs-button>
          </vs-col>
        </vs-row>

        <p class="p-2">Agregar proyecto:</p>
        <vs-row vs-w="12" class="p-1">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="w-full"
          >
            <v-select
              v-model="project"
              :options="projects"
              single
              label="name"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              class="w-full m-1"
              :taggable="true"
              :multiple="true"
              :push-tags="true"
            />
            <vs-button
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-1 m-2"
              @click="addProjectToUser()"
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" class="p-1">
          <vs-col class="w-full">
            <p class="p-2">Proyectos asignados</p>
            <vs-table
              single
              pagination
              :max-items="itemsPerPage"
              :data="this.actualProjects"
              noDataText="No hay proyectos..."
            >
              <div slot="header"></div>
              <template slot="thead">
                <vs-th>Proyecto</vs-th>
                <vs-th>Acciones</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                  <vs-td :data="data[index].project.name">
                    <p class="product-name font-medium">
                      {{ data[index].project.name }}
                    </p>
                  </vs-td>
                  <vs-td :data="data[index].id">
                    <vs-row>
                      <vs-row>
                        <vs-button
                          color="danger"
                          type="flat"
                          icon-pack="feather"
                          icon="icon-trash"
                          class="p-1"
                          @click="deleteProject(data[index].id)"
                        ></vs-button>
                      </vs-row>
                    </vs-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" class="p-1">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="w-full"
          >
            <vs-input
              v-model="pipedriveId"
              label="Pipedrive ID"
              icon-no-border
              class="w-full"
            />

            <vs-button
              v-if="getUser[0].pipedrive_id != pipedriveId"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-8 m-2"
              @click="
                updateUserProfile('pipedrive_id', pipedriveId, 'de pipedrive.')
              "
            ></vs-button>
          </vs-col>
        </vs-row>
      </div>
      <vs-row class="mt-4">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            @click="deleteAccount()"
            color="danger"
            icon-pack="feather"
            icon="icon-trash"
            type="flat"
            >Eliminar cuenta</vs-button
          >
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import S3 from "aws-s3";
import vSelect from "vue-select";
import api from "../../../services/users";
import { roleApi, userApi } from "../services";
import projectApi from "../../projects/services/project.api";
import { country } from "../../../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
import slugify from "../../../helpers/slugify";

export default {
  name: "EdicionUsuarioComponent",
  props: ["userId"],
  components: {
    vSelect
  },
  data() {
    return {
      getUser: [],
      getProjects: [],
      profileNewImage: "",
      profileNewImageTemp: "",
      imageReadyToUplad: false,
      imgUrl: "",
      selectedRoll: "",
      selectedProject: "",
      roles: [],
      role: null,
      user: null,
      firstName: "",
      lastName: "",
      email: "",
      countryArea: null,
      countryAreaStored: null,
      telephone: "",
      projects: [],
      project: null,
      pipedriveId: null,
      countries: [],
      actualProjects: null,
      itemsPerPage: 5
    };
  },
  computed: {
    config() {
      const getCompanyName = slugify(this.$store.state.auth.user.developerName);
      return {
        bucketName: "flattlo-app",
        dirName: `${getCompanyName}/team`,
        region: "us-east-1",
        accessKeyId: process.env.VUE_APP_AWS_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SK,
        s3Url: process.env.VUE_APP_S3_URL
      };
    },
    S3Client() {
      return new S3(this.config);
    }
  },
  watch: {
    async userId(value) {
      await this.getActualUser(value);
    },
    countryArea(value) {
      if (value.prefix) {
        this.countryAreaStored = value.prefix;
      }
    }
  },
  methods: {
    async deleteAccount() {
      try {
        const id = this.userId;
        await api.updateAccount(id, "deleted", true);
        this.$vs.notify({
          title: "¡Usuario eliminado!",
          text: `Eliminación correcta`,
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
        this.$root.$emit("close-popup");
        this.$root.$emit("load-members");
      } catch (e) {
        sentryCaptureException(e, "commercial-team");
        this.$vs.notify({
          title: `¡Error al eliminar suario!`,
          text: "No ha sido posible elimar usuario en este momento.",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check"
        });
      }
    },
    getStatus(status) {
      if (status == true) return "Activo";
      if (status == false) return "Inactivo";
    },
    async getActualUser(userId) {
      try {
        this.getUser = await api.getIndividualUser(userId);
        if (this.getUser.length) {
          this.countryArea = null;
          this.project = null;
          this.actualProjects = null;

          const developer = this.$store.getters["auth/getUser"];
          this.user = this.getUser[0];

          this.firstName = this.user.first_name;
          this.lastName = this.user.last_name;
          this.telephone = this.user.phone;
          this.pipedriveId = this.user.pipedrive_id;
          const accounts = this.user.accounts.length
            ? this.user.accounts[0]
            : null;
          this.email = accounts.email;
          const roles = accounts.account_roles.length
            ? accounts.account_roles[0]
            : null;
          this.role = roles.role.name;
          this.roles = await roleApi.list();
          this.actualProjects = this.user.user_projects.length
            ? this.user.user_projects
            : null;
          this.projects = await projectApi.listProjects(developer.developerId);
          const countries = await country.getCountries();
          this.countryAreaStored = this.user.area_code;

          const setCountryPrefix = countries.find(
            item => item.telephone_prefix === this.user.area_code
          );
          if (setCountryPrefix) {
            this.countryArea = `(${setCountryPrefix.telephone_prefix}) ${setCountryPrefix.name}`;
          }
          this.countries = countries.map(item => {
            return {
              value: item.id,
              label: `(${item.telephone_prefix}) ${item.name}`,
              prefix: item.telephone_prefix
            };
          });
        }
      } catch (e) {
        sentryCaptureException(e, "commercial-team");
        this.$vs.notify({
          title: "¡Error al editar el usuario!",
          text: "No ha sido posible editar usuario en este momento.",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check"
        });
      }
    },

    statusColor(status) {
      if (status == true) return "success";
      if (status == false) return "danger";
    },
    closeEditUser(usr) {
      this.$store.dispatch("editarUsuario", usr);
    },
    mostrarImagenPerfil() {
      this.profileNewImage = null;
      this.profileNewImage = event.target.files[0];
      this.imageReadyToUplad = true;

      const render = new FileReader();
      render.readAsDataURL(this.profileNewImage);
      render.onload = e => {
        this.profileNewImageTemp = e.target.result;
      };
    },

    cancelUpload() {
      this.profileNewImageTemp = "";
      this.profileNewImage = null;
      this.imageReadyToUplad = false;
    },
    async savePic() {
      const userId = this.getUser[0];
      try {
        this.S3Client.uploadFile(
          this.profileNewImage,
          `${userId.first_name}_${userId.last_name}`
        ).then(data => {
          const logoUrl = data.location;
          this.imgUrl = logoUrl;
          this.updateUserProfile("avatar", this.imgUrl, "Foto de Perfil");
          this.profileNewImage = "";
          this.profileNewImageTemp = "";
          this.imageReadyToUplad = false;
          this.$root.$emit("load-members");
        });
      } catch (e) {
        sentryCaptureException(e, "commercial-team");
        this.$vs.notify({
          title: `¡Error al actualizar foto de  usuario!`,
          text: "No ha sido posible actualizar dato en este momento.",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check"
        });
      }
    },

    async updateUserProfile(fieldForUpdate, valueToAdd, descriptionToUser) {
      try {
        const id = this.userId;
        if (fieldForUpdate === "area_code") {
          valueToAdd = valueToAdd.prefix;
        }
        await api.editUserByPk(id, fieldForUpdate, valueToAdd);
        this.$vs.notify({
          title: "¡Campo editado!",
          text: `El campo ${descriptionToUser} fue editado correctamente.`,
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
        await this.getActualUser(id);
        this.$root.$emit("load-members");
      } catch (e) {
        sentryCaptureException(e, "commercial-team");
        this.$vs.notify({
          title: `¡Error al actualizar ${descriptionToUser} usuario!`,
          text: "No ha sido posible actualizar dato en este momento.",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check"
        });
      }
    },
    async updateUserAccount(fieldForUpdate, valueToAdd, descriptionToUser) {
      try {
        const id = this.$store.state.editUserID;
        await api.updateAccount(id, fieldForUpdate, valueToAdd);
        this.$vs.notify({
          title: "¡Campo editado!",
          text: `El campo ${descriptionToUser} fue editado correctamente.`,
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
        await this.getActualUser(id);
        this.$root.$emit("load-members");
      } catch (e) {
        sentryCaptureException(e, "commercial-team");
        this.$vs.notify({
          title: `¡Error al actualizar ${descriptionToUser} usuario!`,
          text: "No ha sido posible actualizar dato en este momento.",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check"
        });
      }
    },

    async updateUserRol() {
      try {
        const userId = this.getUser[0].accounts[0].id;
        await api.updateRol(userId, this.role.id);
        this.$vs.notify({
          title: "¡Campo editado!",
          text: `El campo de Rol fue editado correctamente.`,
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
        await this.getActualUser(userId);
        this.$root.$emit("load-members");
      } catch (e) {
        sentryCaptureException(e, "commercial-team");
        this.$vs.notify({
          title: `¡Error al actualizar rol usuario!`,
          text: "No ha sido posible actualizar dato en este momento.",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check"
        });
      }
    },

    async addProjectToUser() {
      try {
        for await (const project of this.project) {
          await api.addProjectToUser(project.id, this.user.id);
        }
        this.$vs.notify({
          title: "¡Proyecto agregado!",
          text: `El proyecto fue agregado exitosamente.`,
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
        await this.getActualUser(this.user.id);
        this.$root.$emit("load-members");
      } catch (e) {
        sentryCaptureException(e, "commercial-team");
        this.$vs.notify({
          title: `¡Error al actualizar proyecto usuario!`,
          text: "No ha sido posible actualizar dato en este momento.",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check"
        });
      }
    },
    async deleteProject(userProjectId) {
      try {
        await userApi.deleteUserProjectById(userProjectId);
        this.$vs.notify({
          title: "¡Proyecto eliminado!",
          text: `El proyecto fue eliminado exitosamente.`,
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
        this.actualProjects = await userApi.getUserProject(this.user.id);
        await this.getActualUser(this.user.id);
        this.$root.$emit("load-members");
      } catch (e) {
        sentryCaptureException(e, "commercial-team");
        this.$vs.notify({
          title: `¡Error al actualizar proyecto usuario!`,
          text: "No ha sido posible actualizar dato en este momento.",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.specialAvatarBorder {
  border: solid 4px #0d47a1;
}
@media (min-width: 300px) and (max-width: 600px) {
  #avatar-col {
    display: block;
    margin: 0 auto;
  }
}
</style>
