import gql from "graphql-tag";
import graphqlClient from "../graphqlClient";

const getTeamMembers = async developerID => {
  const response = await graphqlClient.query({
    query: gql`
      query($developer: uuid!) {
        property_developer(where: { id: { _eq: $developer } }) {
          accounts {
            id
            user {
              id
              first_name
              last_name
              avatar
              phone
              area_code
              pipedrive_id
              user_projects {
                id
                project {
                  id
                  name
                }
              }
              accounts {
                id
                email
                last_login
                active
                user_id
                deleted
                account_roles {
                  id
                  role {
                    name
                  }
                }
              }
              quotes_aggregate {
                aggregate {
                  count
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      developer: developerID
    }
  });
  return response.data.property_developer[0].accounts;
};

const getIndividualUser = async user => {
  const response = await graphqlClient.query({
    query: gql`
      query getIndiviualUser($userID: uuid!) {
        user(where: { id: { _eq: $userID } }) {
          id
          first_name
          last_name
          avatar
          phone
          area_code
          pipedrive_id
          user_projects {
            id
            project {
              id
              name
            }
          }
          accounts {
            id
            user_id
            email
            last_login
            active
            account_roles {
              id
              role {
                name
              }
            }
          }
          quotes_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    `,
    variables: {
      userID: user
    }
  });
  return response.data.user;
};

const createuser = async userObject => {
  const response = await graphqlClient.query({
    query: gql`
      mutation insertUser($userObject: user_insert_input!) {
        insert_user_one(object: $userObject) {
          id
        }
      }
    `,
    variables: {
      userObject: {
        first_name: userObject.first_name,
        last_name: userObject.last_name,
        phone: userObject.phone,
        area_code: userObject.area_code,
        pipedrive_id: userObject.pipedrive_id,
        accounts: {
          data: {
            email: userObject.email
          }
        }
      }
    }
  });
  return response.data.insertUser;
};

const editUserByPk = async (id, field, value) => {
  const response = await graphqlClient.query({
    query: gql`
      mutation update_user($userId: uuid!, $fieldToEdit: user_set_input) {
        update_user_by_pk(pk_columns: { id: $userId }, _set: $fieldToEdit) {
          id
        }
      }
    `,
    variables: {
      userId: id,
      fieldToEdit: {
        [field]: value
      }
    }
  });
  return response.data;
};

const updateAccount = async (id, editValue, value) => {
  const response = await graphqlClient.query({
    query: gql`
      mutation updateAccount(
        $fieldToUpdate: account_set_input
        $userId: uuid!
      ) {
        update_account(
          where: { user_id: { _eq: $userId } }
          _set: $fieldToUpdate
        ) {
          affected_rows
          returning {
            id
          }
        }
      }
    `,
    variables: {
      userId: id,
      fieldToUpdate: {
        [editValue]: value
      }
    }
  });
  return response.data;
};

const updateRol = async (id, rId) => {
  const response = await graphqlClient.query({
    query: gql`
      mutation updateRol($id: uuid!, $rollID: uuid!) {
        update_account_role(
          where: { account_id: { _eq: $id } }
          _set: { role_id: $rollID }
        ) {
          returning {
            id
          }
        }
      }
    `,
    variables: {
      id: id,
      rollID: rId
    }
  });
  return response.data;
};

const addProjectToUser = async (projectId, userId) => {
  const response = await graphqlClient.query({
    query: gql`
      mutation addProyectToUser($proyectValues: user_project_insert_input!) {
        insert_user_project(objects: [$proyectValues]) {
          returning {
            id
          }
        }
      }
    `,
    variables: {
      proyectValues: {
        project_id: projectId,
        user_id: userId
      }
    }
  });
  return response.data;
};

const deleteProjectFromUser = async (userId, proyectId) => {
  const response = await graphqlClient.query({
    query: gql`
    mutation deleteProyectFromUser($userId:uuid!, $projId:uuid!){
  delete_user_project(where: {project_id: {_eq: $projId}, _and: {user_id: {_eq: $userId}}}){
    returning{
      id
    }
  }
}
    `,
    variables: {
        userId: userId,
        projId: proyectId
    }
  });
  return response.data;
};

export default {
  getTeamMembers,
  getIndividualUser,
  createuser,
  editUserByPk,
  updateAccount,
  updateRol,
  addProjectToUser,
  deleteProjectFromUser
};
