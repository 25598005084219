<template>
  <div>
    <br />
    <div id="data-list-thumb-view" class="data-list-container">
      <vs-table
        ref="table"
        single
        :max-items="itemsPerPage"
        :data="members"
        noDataText="👨‍💼👩‍💼💼 No hay usuario creados..."
      >
        <div slot="header">
          <vs-col vs-type="flex" vs-justify="flex-end">
            <div>
              <vs-button
                class="m-1 mb-4"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-user-plus"
                @click="popupActive3 = true"
                >Nuevo Usuario</vs-button
              >
            </div>
          </vs-col>
        </div>
        <template slot="thead">
          <vs-th>Perfil</vs-th>
          <vs-th>Nombre</vs-th>
          <vs-th>Rol</vs-th>
          <vs-th>Proyectos</vs-th>
          <vs-th>Status</vs-th>
          <vs-th>Acciones</vs-th>
        </template>
        <vs-popup
          background-color="rgba(255,255,255,.6)"
          title="✔ Confirmación de eliminación"
          :active.sync="popupActive2"
        >
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <h4 class="mt-2 mb-2">
                ¿Desea eliminar al usuario de la plataforma?
              </h4>
            </vs-col>
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <vs-button
                  class="mt-4 mb-4"
                  color="success"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-alert-octagon"
                  @click="deleteSeller()"
                  >Sí, Bloquear usuario</vs-button
                >
              </vs-col>
            </vs-row>
          </vs-row>
        </vs-popup>

        <vs-popup
          title="👩‍💼👨‍💼💼 Formulario de usuario nuevo"
          :active.sync="popupActive3"
        >
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-input
                size="large"
                icon-pack="feather"
                class="fZise p-2"
                icon="icon-user"
                label="Nombre"
                color="primary"
                type="text"
                v-validate="'required'"
                name="Primer nombre"
                v-model="firstName"
              />
            </vs-col>
            <div></div>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-input
                size="large"
                icon-pack="feather"
                class="fZise p-2"
                icon="icon-users"
                label="Apellido"
                color="primary"
                type="text"
                v-validate="'alpha|required'"
                name="Apellido"
                v-model="lastName"
              />
            </vs-col>
            <vs-col
              class="-mt-2"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <label for="" class="mt-6 vs-input--label">Código de área</label>
              <v-select
                class="fZise p-2"
                v-model="countryArea"
                :options="countries"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-input
                size="large"
                icon-pack="feather"
                class="fZise p-2"
                icon="icon-phone"
                label="Teléfono"
                color="primary"
                type="phone"
                v-validate="'required'"
                name="Correo"
                v-model="telephone"
              />
            </vs-col>
            <vs-col vs-justify="center" vs-align="center" vs-w="12">
              <vs-input
                size="large"
                icon-pack="feather"
                class="fZise p-2"
                icon="icon-mail"
                label="Email"
                type="mail"
                color="primary"
                v-validate="'email|required'"
                name="Correo"
                v-model="email"
              />
              <span class="text-danger text-sm ml-2" v-show="emailError"
                >Correo electrónico ya se encuentra registrado</span
              >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <div class="container-pipedrive">
                <vs-input
                  size="large"
                  icon-pack="feather"
                  class="fZise p-2"
                  icon="icon-award"
                  label="Pipedrive ID"
                  type="text"
                  color="primary"
                  name="Pipedrive ID"
                  v-model="pipedriveId"
                />
              </div>
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
              class="mt-2"
            >
              <p class="m-2">Rol:</p>
              <v-select
                class="fZise p-2"
                label="name"
                v-model="role"
                :options="roles"
              />
            </vs-col>
            <vs-col
              vs-justify="center"
              vs-align="center"
              vs-w="12"
              class="mt-2"
            >
              <p class="m-2">Proyecto:</p>
              <v-select
                class="fZise p-2"
                label="name"
                v-model="project"
                :options="projects"
                :taggable="true"
                :multiple="true"
                :disabled="assignAllProjects"
              />
              <vs-row>
                <vs-col vs-type="flex" vs-w="12">
                  <vs-switch class="ml-2" v-model="assignAllProjects" />
                  <label for="">&nbsp;Todos los proyectos</label>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <vs-button
                ref="loadableButton"
                icon-pack="fas"
                color="success"
                icon="fa-user-plus"
                id="button-with-loading"
                class="w-full mt-4 vs-con-loading__container mt-2"
                @click="openLoadingContained, generatePass(), createUser()"
                :disabled="!fieldsReady"
                >Crear usuario</vs-button
              >
            </vs-col>
          </vs-row>
        </vs-popup>

        <vs-popup
          title="✔💼👨‍💼 Usuario creado correctamente."
          background-color="primary"
          :active.sync="popupActive"
        >
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            Utilice el siguiete password temporal para el primer inicio de
            sessión:
            <br />
          </vs-col>
          <vs-row>
            <vs-col>
              <br />
              <vs-input v-model="tempPass" class="inline-flex" />
              <vs-button @click="copy()" color="success">Copiar</vs-button>
            </vs-col>
          </vs-row>
        </vs-popup>

        <vs-popup
          title="📝👨‍💼 Edición de usuario."
          background-color="primary"
          :active.sync="popupEditTeamMember"
        >
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <EdicionUsuarioComponent :userId="userSelected" />
            </vs-col>
          </vs-row>
        </vs-popup>
        <tbody>
          <vs-tr
            :data="seller"
            :key="index"
            v-for="(seller, index) in members"
            v-show="seller.user.accounts[0].deleted == false"
          >
            <vs-td class="img-container p-4">
              <vs-avatar size="60px" :src="seller.user.avatar" />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium">
                {{ seller.user.first_name }} {{ seller.user.last_name }}
              </p>
              <span>{{ seller.user.accounts[0].email }}</span>
            </vs-td>

            <vs-td>
              <p>{{ seller.user.accounts[0].account_roles[0].role.name }}</p>
            </vs-td>

            <vs-td v-if="seller.user.user_projects.length <= 0">
              <vs-chip>Todos</vs-chip>
            </vs-td>

            <vs-td v-if="seller.user.user_projects.length > 0">
              <vs-chip
                closable
                @click="deleteProjectFromUser(seller.user.id, p.project.id)"
                v-for="p in seller.user.user_projects"
                :key="p.id"
                >{{ p.project.name }}</vs-chip
              >
            </vs-td>

            <vs-td>
              <vs-chip :color="statusColor(seller.user.accounts[0].active)">{{
                getStatus(seller.user.accounts[0].active)
              }}</vs-chip>
            </vs-td>
            <vs-td>
              <vs-row>
                <vx-tooltip v-show="user.role === 'ADMIN'" color="primary" text="Editar" position="bottom">
                  <vs-button
                    @click="editUser(seller.user.accounts[0].user_id)"
                    color="primary"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-edit"
                    class="p-1"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip
                  v-show="seller.user.accounts[0].active == true && user.role === 'ADMIN'"
                  color="danger"
                  text="Bloquear"
                  position="bottom"
                >
                  <vs-button
                    color="danger"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-user-x"
                    class="p-1"
                    v-show="seller.user.accounts[0].active == false"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip
                  v-show="seller.user.accounts[0].active == false && user.role === 'ADMIN'"
                  color="success"
                  text="Activar"
                  position="bottom"
                >
                  <vs-button
                    @click="
                      updateUserAccount(
                        seller.user.id,
                        'active',
                        true,
                        'de activación.'
                      )
                    "
                    color="success"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-user-check"
                    class="p-1"
                    v-show="seller.user.accounts[0].active == false"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip
                  v-show="user.role === 'ADMIN'"
                  color="danger"
                  text="Bloquear Acceso"
                  position="bottom"
                >
                  <vs-button
                    v-show="seller.user.accounts[0].active == true"
                    color="danger"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-alert-octagon"
                    @click="
                      updateUserAccount(
                        seller.user.id,
                        'active',
                        false,
                        'de activación.'
                      )
                    "
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </tbody>
      </vs-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import EdicionUsuarioComponent from "./EdicionUsuarioComponent";
const generator = require("generate-password");
import api from "../../../services/users";
import { bus } from "../../../main";
import { roleApi, accountApi } from "../services";
import projectApi from "../../projects/services/project.api";
import { country } from "../../../services";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  data() {
    return {
      itemsPerPage: 100,
      popupActive: false,
      popupActive2: false,
      popupActive3: false,
      backgroundLoading: "success",
      colorLoading: "#ffffff",
      tempPass: "",
      popupEditTeamMember: false,
      members: [],
      roles: [],
      firstName: "",
      lastName: "",
      countryArea: "",
      telephone: "",
      email: "",
      pipedriveId: null,
      role: null,
      projects: [],
      project: null,
      countries: [],
      userSelected: null,
      emailError: false,
      assignAllProjects: false
    };
  },
  components: {
    vSelect,
    EdicionUsuarioComponent
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters["companyProfile"],
    fieldsReady() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.countryArea ||
        !this.telephone ||
        !this.email ||
        !this.role ||
        (!this.project && !this.assignAllProjects)
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  async mounted() {
    const developer = this.$store.getters["auth/getUser"];
    this.members = await api.getTeamMembers(developer.developerId);
    this.roles = await roleApi.list();
    this.roles =  (this.user.role === 'SUPERVISOR') ? this.roles.filter(item => item.name === 'SELLER') : this.roles;
    
    this.projects = await projectApi.listProjects(developer.developerId);

    const countries = await country.getCountries();

    this.countries = countries.map(item => {
      return {
        value: item.id,
        telephonePrefix: item.telephone_prefix,
        label: `${item.name} ${item.telephone_prefix}`
      };
    });

    this.countryArea = this.countries.length ? this.countries[0] : null;

    this.$root.$on("load-members", async () => {
      this.members = await api.getTeamMembers(developer.developerId);
    });

    this.$root.$on("close-popup", async () => {
      this.popupEditTeamMember = false;
    });
  },
  watch: {
    email() {
      this.emailError = false;
    },
    assignAllProjects(value) {
      if (value) {
        this.project = null;
      }
    }
  },
  methods: {
    deleteProjectFromUser(userId, proyectId) {
      const res = api.deleteProjectFromUser(userId, proyectId);
      res.then(() => {
        this.$vs.notify({
          title: "¡Proyecto Eliminado!",
          text: `Proyecto eliminado de usuario correctamente.`,
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
      });
      bus.$emit("refreshComponent");
    },
    updateUserAccount(id, fieldForUpdate, valueToAdd, descriptionToUser) {
      const res = api.updateAccount(id, fieldForUpdate, valueToAdd);
      res.then(() => {
        this.getTeamMembers();
        this.$vs.notify({
          title: "¡Campo editado!",
          text: `El campo ${descriptionToUser} fue editado correctamente.`,
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
      });
    },
    getTeamMembers() {
      const devId = this.$store.getters["auth/getUser"];
      const res = api.getTeamMembers(devId.developerId);
      res.then(r => {
        this.members = r;
      });
    },
    editUser(userId) {
      this.popupEditTeamMember = true;
      this.userSelected = userId;
    },
    getStatus(status) {
      if (status == true) return "Activo";
      if (status == false) return "Inactivo";
    },
    statusColor(status) {
      if (status == true) return "success";
      if (status == false) return "danger";
    },
    openLoadingContained() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45
      });
      setTimeout(() => {
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
      }, 3000);
    },
    async createUser() {
      try {
        const emailAccounts = await accountApi.getByEmail(this.email);

        if (emailAccounts.length) {
          this.emailError = true;
          return false;
        }

        const developer = this.$store.getters["auth/getUser"];

        const account = await accountApi.createAccount(
          this.firstName,
          this.lastName,
          this.countryArea.telephonePrefix,
          this.telephone,
          this.email,
          this.pipedriveId,
          this.tempPass,
          this.role ? this.role.id : null
        );

        await accountApi.addAccountToDeveloper(
          account.id,
          developer.developerId
        );

        if (this.assignAllProjects) {
          this.project = this.projects;
        }

        for await (const project of this.project) {
          await api.addProjectToUser(project.id, account.user_id);
        }

        this.members = await api.getTeamMembers(developer.developerId);
        this.popupActive3 = false;
        this.popupActive = true;
        this.firstName = "";
        this.lastName = "";
        this.telephone = "";
        this.email = "";
        this.role = null;
        this.countryArea = "";
        this.pipedriveId = null;
        this.project = null;
        this.assignAllProjects = false;

        this.$vs.notify({
          title: "¡Usuario creado!",
          text: "Usuario creado y asignado.",
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
      } catch (e) {
        sentryCaptureException(e, "commercial-team");
        this.$vs.notify({
          title: "¡Error al crear el usuario!",
          text: "No ha sido posible crear usuario en este momento.",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check"
        });
      }
    },
    generatePass() {
      this.tempPass = "";
      let password = generator.generate({
        length: 10,
        numbers: true
      });
      this.tempPass = password;
    },
    copy() {
      this.$copyText(this.tempPass);
      this.$vs.notify({
        title: "¡Copiada! (ctrl + c)",
        text: "La clave se encuentra en su portapales.",
        color: "success",
        iconPack: "feather",
        icon: "icon-check"
      });
    }
  }
};
</script>

<style lang="scss">
.container-pipedrive {
  margin-top: 10px;
  background-color: #000 !important;
  width: 100%;
  border-radius: 5px;
  label {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
  }
  .vs-con-input {
    margin-top: 10px;
  }
}
.fZise {
  width: 100%;
}
#data-list-thumb-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 0px;
      margin-right: 0px;
      > span {
        display: flex;
        flex-grow: 1;
      }
      .vs-table--search {
        padding-top: 0;
        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;
          & + i {
            left: 1rem;
          }
          &:focus + i {
            left: 1rem;
          }
        }
      }
    }
    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;
      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container 
            // width: 1rem;
            // background: #fff;
            span {
            display: flex;
            justify-content: left;
          }
          .product-img {
            height: 60px;
            border-radius: 100px;
          }
        }
      }
      td.td-check {
        padding: 10px !important;
      }
    }
  }
  .vs-table--thead {
    th {
      padding-top: 0;
      padding-bottom: 0;
      .vs-table-text {
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    th.td-check {
      padding: 0 15px !important;
    }
    tr {
      background: none;
      box-shadow: none;
    }
  }
  .vs-table--pagination {
    justify-content: center;
  }
  .dropdown-button-container {
    display: flex;
    align-items: center;
    .btnx {
      border-radius: 5px 0px 0px 5px;
    }
    .btn-drop {
      border-radius: 0px 5px 5px 0px;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
}
</style>
